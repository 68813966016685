<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <span
            >员工名称：
            <el-input
              placeholder="请输入员工名称"
              clearable
              v-model="parameter.userName"
              class="ipt_width"
            >
            </el-input
          ></span>

          <!-- <el-input placeholder="请输入项目名称" class="ipt_width" clearable v-model="parameter.projectName">
          </el-input> -->
          <span
            >项目名：
            <el-select
              v-model="parameter.projectId"
              @change="dataUpdate"
              clearable
              filterable
              placeholder="请输入或选择项目名"
              class="ipt_width project_width"
            >
              <el-option
                v-for="item in projectList"
                :key="item.id"
                :label="item.projectName"
                :value="item.id"
              >
              </el-option> </el-select
          ></span>

          <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
            >搜索</el-button
          >
          <el-button type="primary" icon="el-icon-edit" @click="handleReset()">清空</el-button>
          <!-- <el-button type="primary" icon="el-icon-finished" @click="all_audit()"
            >批量通过</el-button
          > -->
          <el-button
            type="primary"
            style="width: 125px"
            icon="el-icon-sort-down"
            @click="all_deliver()"
            >批量转交</el-button
          >
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="activeName" class="fullScreenMainHeader">
            <el-tab-pane label="待审核"></el-tab-pane>
            <el-tab-pane label="已审核"></el-tab-pane>
          </el-tabs>
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :key="Math.random()"
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            @selection-change="handleSelectionChange"
            @cell-click="handleCellClick"
            v-loading="loading"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column
              align="center"
              type="index"
              label="序号"
              width="50"
              :show-overflow-tooltip="false"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="userName"
              label="填报人"
              width="90"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="departmentName"
              label="所属部门"
              width="110"
            ></el-table-column>
            <el-table-column align="center" prop="projectName" label="项目名称" min-width="240">
              <template slot-scope="scope">
                <span v-if="scope.row.manhourType == 0">{{ scope.row.projectName }}</span>
                <span v-if="scope.row.manhourType == 1">{{ scope.row.workName }} </span>
                <span v-if="scope.row.manhourType == 2">{{ scope.row.businessName }} </span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="projectTaskName"
              label="项目任务名称"
              min-width="220"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="workContent"
              label="工作内容"
              min-width="240"
            ></el-table-column>
            <el-table-column align="center" prop="manhourType" label="有无重要活动" width="110">
              <template slot-scope="scope">
                <span v-if="scope.row.outgoingType == 'HAVE'">有</span>
                <span v-else>无</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="isTraveling" label="是否外勤" width="110">
              <template slot-scope="scope">
                <span v-if="scope.row.isTraveling == 'Y'">是</span>
                <span v-else>否</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="manhourType" label="类型" width="80">
              <template slot-scope="scope">
                <span v-if="scope.row.manhourType == 0">项目工时</span>
                <span v-if="scope.row.manhourType == 1">管理工时</span>
                <span v-if="scope.row.manhourType == 2">商务工时</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="checkEmployee" sortable label="审核人" width="90">
              <template slot-scope="scope">
                <span v-if="scope.row.checkEmployee">{{ scope.row.checkEmployee }}</span>
                <span v-else>无 </span>
              </template>
            </el-table-column>
            <!-- 是否转交、转交人 -->
            <el-table-column align="center" prop="transferStatus" label="是否转交" width="80">
              <template slot-scope="scope">
                <span v-if="scope.row.transferStatus == '1'">是</span>
                <span v-else>否</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="transferName" label="转交人" width="90">
              <template slot-scope="scope">
                <span v-if="scope.row.transferName">{{ scope.row.transferName }}</span>
                <span v-else>无 </span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="manhourDate"
              sortable
              label="工时日期"
              width="110"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="submitDate"
              sortable
              label="填报时间"
              width="160"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="checkTime"
              v-if="activeName == 1"
              label="审核时间"
              width="160"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="hour"
              label="填报工时"
              width="90"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="auditHour"
              label="审核工时"
              v-if="activeName == 1"
              width="90"
            ></el-table-column>
            <el-table-column prop="title" align="center" fixed="right" width="70" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="text_Examine_Bgc"
                  v-if="activeName == '0'"
                  @click.stop="audit(scope.row, 0)"
                  >审核
                </el-button>
                <!-- <el-button
                  type="text"
                  size="small"
                  class="text_Miantan_Bgc"
                  v-if="activeName == '0'"
                  @click.stop="deliver(scope.row)"
                  >转交
                </el-button> -->
                <el-button
                  type="text"
                  class="text_Details_Bgc"
                  size="small"
                  v-if="activeName == '1'"
                  @click.stop="audit(scope.row, 1)"
                  >详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <EditDialog width="65%" :isShow.sync="showEditDialog" :loading.sync="editDialogLoading">
      <div slot="title" class="title">工时审批</div>
      <div slot="content" class="content">
        <div class="dg_title">工时日期：{{ dg_form.manhourDate }}</div>
        <!-- <div class="dg_title">填报日期：{{dg_form.submitDate}}</div> -->
        <el-form label-width="4em" :model="dg_form" ref="dg_form">
          <!-- YLZ开发审批内容 -->
          <el-descriptions
            :column="7"
            border
            direction="vertical"
            :label-style="label_style"
            :contentStyle="content_style"
          >
            <!-- 第一行 -->
            <el-descriptions-item label="填报人">{{ dg_form.userName }}</el-descriptions-item>
            <el-descriptions-item label="部门">{{ dg_form.departmentName }}</el-descriptions-item>
            <el-descriptions-item label="填报时间">{{ dg_form.submitDate }}</el-descriptions-item>
            <el-descriptions-item label="有无重要活动">
              <div>
                <div v-if="dg_form.outgoingType == 'HAVE'">
                  <span style="margin-right: 20px">有</span>
                  <el-button type="primary" size="mini" plain @click="isOutReportDialogShow = true"
                    >查看报告</el-button
                  >
                </div>
                <div v-else>无</div>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="是否外勤">
              <div>
                <div v-if="dg_form.isTraveling == 'Y'">
                  <span style="margin-right: 20px">是</span>
                  <el-button type="primary" size="mini" plain @click="isOutWorkDialogShow = true"
                    >查看报告</el-button
                  >
                </div>
                <div v-else>否</div>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="当天总上报工时">
              {{ zgs }}
            </el-descriptions-item>
            <el-descriptions-item label="上报工时" :span="1">
              {{ dg_form.hour }}
            </el-descriptions-item>
            <!-- 第二行 -->
            <el-descriptions-item label="项目名称" v-if="dg_form.manhourType == 0" :span="3"
              >{{ dg_form.projectName }}
            </el-descriptions-item>
            <el-descriptions-item label="组织名称" v-if="dg_form.manhourType == 1" :span="5">
              {{ dg_form.deptName }}
            </el-descriptions-item>
            <el-descriptions-item label="商务名称" v-if="dg_form.manhourType == 2" :span="5">
              {{ dg_form.businessName }}
            </el-descriptions-item>
            <el-descriptions-item label="任务名称" v-if="dg_form.manhourType == 0" :span="2">
              {{ dg_form.projectTaskName }}
            </el-descriptions-item>
            <el-descriptions-item
              :contentClassName="
                dg_form.manhourType == 0
                  ? 'projectType'
                  : dg_form.manhourType == 1
                  ? 'deptType'
                  : 'businessType'
              "
              label="工时类型"
              :span="1"
            >
              {{
                dg_form.manhourType == 0 ? '生产级' : dg_form.manhourType == 1 ? '管理级' : '商务级'
              }}
            </el-descriptions-item>
            <el-descriptions-item :span="1" v-if="!isDetail">
              <template slot="label">
                <span style="color: red">*</span>
                审核工时
              </template>
              <el-input
                v-model.trim="auditHour"
                placeholder="请输入审核工时"
                @input="is_number()"
                @blur="checkAuditHour(dg_form.hour)"
              ></el-input>
              <div class="color_r" v-if="isAuditHourTipsShow">{{ auditHourTips }}</div>
            </el-descriptions-item>
            <el-descriptions-item label="审核工时" v-else :span="1"
              >{{ dg_form.auditHour }}
            </el-descriptions-item>
            <!-- 第三行 -->
            <el-descriptions-item :span="4">
              <div slot="label">
                <span>工作内容</span>
              </div>
              <div class="workContentStyleOpen">
                {{ dg_form.workContent }}
              </div>
              <!-- <el-input type="textarea" :rows="3" readonly v-model="dg_form.workContent">
              </el-input> -->
            </el-descriptions-item>

            <el-descriptions-item
              label="历史审批意见"
              :span="3"
              v-if="!isDetail && dg_form.checkOpinion"
            >
              <div class="workContentStyleOpen">
                <el-form-item prop="checkOpinion" ref="checkOpinion" label-width="0">
                  <el-input
                    readonly
                    type="textarea"
                    v-model="dg_form.checkOpinion"
                    :autosize="{ minRows: 8, maxRows: 16 }"
                  ></el-input>
                </el-form-item>
              </div>
            </el-descriptions-item>

            <el-descriptions-item label="审批内容" :span="3" v-if="isDetail">
              <div class="workContentStyleOpen">
                <el-form-item label="审批" prop="isCheckPass" ref="isCheckPass">
                  <!-- <el-radio-group v-model="dg_form.isCheckPass" disabled>
                    <el-radio :label="2">通过</el-radio>
                    <el-radio :label="3">不通过</el-radio>
                  </el-radio-group> -->
                  <el-tag
                    type="success"
                    v-if="
                      dg_form.checkStatus == 1 &&
                      dg_form.isApproved == 'Y' &&
                      dg_form.isTraveling == 'Y'
                    "
                    size="medium"
                    effect="dark"
                    >通过</el-tag
                  >
                  <el-tag
                    type="success"
                    v-else-if="dg_form.isCheckPass == 2"
                    size="medium"
                    effect="dark"
                    >通过</el-tag
                  >
                  <el-tag
                    type="danger"
                    v-else-if="dg_form.isCheckPass == 3"
                    size="medium"
                    effect="dark"
                    >不通过</el-tag
                  >
                </el-form-item>
                <el-form-item label="意见" prop="checkOpinion" ref="checkOpinion">
                  <el-input
                    readonly
                    type="textarea"
                    v-model="dg_form.checkOpinion"
                    :autosize="{ minRows: 8, maxRows: 16 }"
                  ></el-input>
                </el-form-item>
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </el-form>
      </div>
      <div slot="footer" class="footer">
        <el-button type="success" :loading="loading" @click="showPassOpinion" v-if="!isDetail"
          >通过</el-button
        >
        <el-button type="danger" :loading="loading" @click="showUnPassOpinion" v-if="!isDetail"
          >不通过</el-button
        >
        <el-button type="primary" @click="deliver(dg_form)" v-if="!isDetail">转交</el-button>
        <el-button type="warning" plain @click="showEditDialog = false">取消</el-button>
      </div>
    </EditDialog>

    <!-- <EditDialog width="600px" :isShow.sync="showDeliver" >
      <div slot="title" class="title">转交工时审核</div>
      <div slot="content" class="content">
        <el-tree
          ref="principalUserDataTree"
          show-checkbox
          :load="loadNode"
          :lazy="true"
          :default-expand-all="false"
          :expand-on-click-node="false"
          :check-on-click-node="true"
          @check-change="treeCheck"
          node-key="id"
          :props="{
            children: 'zones',
            label: 'label',
            isLeaf: 'leaf',
          }">
          <div class="custom-tree-node" slot-scope="{ node, data }">
            <template v-if="data.type == 'dept'">
              <i class="iconfont iconcompany" style="color: #399ffb; margin-right: 0px"></i>
              {{ data.deptId | cascader(deptData, 'id', 'deptName') }}
            </template>
            <template v-else>
              <i class="iconfont iconbussiness-man" style="color: #399ffb; margin-right: 0px"></i>
              {{ data.loginName }}
            </template>
          </div>
        </el-tree>
      </div>
    </EditDialog> -->

    <el-dialog title="工作内容" :visible.sync="workContentDialogVisible" width="40%">
      <div class="dialogContentHeight">{{ workContentDialog }}</div>
    </el-dialog>

    <!-- 审核通过意见框 -->
    <EditDialog
      width="60%"
      :isShow.sync="passDialogVisible"
      @submit="submitPass"
      :loading.sync="editDialogLoading"
    >
      <template v-slot:title>
        <span style="color: #409eff">{{
          dg_form.outgoingType == 'HAVE'
            ? dg_form.isTraveling == 'Y'
              ? '重要活动报告与外勤报告和审批通过意见'
              : '重要活动报告与审批通过意见'
            : dg_form.isTraveling == 'Y'
            ? '外勤报告与审批通过意见'
            : '审批通过意见'
        }}</span>
      </template>
      <template v-slot:content>
        <template v-if="dg_form.outgoingType == 'HAVE'">
          <div class="hour-audit">
            <div class="out-report" style="min-height: 480px">
              <el-form
                :model="manhourOutgoing"
                ref="outReportForm"
                label-width="107px"
                label-position="left"
              >
                <el-form-item label="报告类型" prop="type">
                  <el-radio-group v-model="manhourOutgoing.type" disabled>
                    <el-radio label="WRITTEN">书面报告</el-radio>
                    <el-radio label="ORAL">口头报告</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="活动开始时间"
                  prop="startDate"
                  style="width: 50%; display: inline-block"
                >
                  <el-date-picker
                    v-model="manhourOutgoing.startDate"
                    align="right"
                    type="date"
                    placeholder="选择日期"
                    disabled
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item
                  label="活动结束时间"
                  style="width: 50%; display: inline-block"
                  prop="endDate"
                >
                  <el-date-picker
                    v-model="manhourOutgoing.endDate"
                    align="right"
                    type="date"
                    placeholder="选择日期"
                    disabled
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="活动地点" prop="place">
                  <el-input v-model.trim="manhourOutgoing.place" disabled></el-input>
                </el-form-item>
                <el-form-item :error="manhourOutgoing.fileErrMsg">
                  <template slot="label">
                    <template> 附件</template>
                  </template>
                  <div
                    v-for="(n, index) in manhourOutgoing.manhourFileList"
                    :key="index"
                    style="margin-top: 5px"
                    class="file-list"
                  >
                    <a
                      class="fj-text file-name"
                      target="_blank"
                      style="text-decoration: revert; color: #409eff; padding-right: 8px"
                      :href="`${filepath}${n.filePath}`"
                    >
                      {{ n.fileName }}
                    </a>
                  </div>
                </el-form-item>
                <el-form-item label="活动说明" prop="content ">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 8, maxRows: 9999 }"
                    v-model.trim="manhourOutgoing.content"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="text-center-btm">
              <el-form
                v-if="dg_form.isTraveling == 'Y'"
                style="width: 100%"
                :model="outWorkInfo"
                label-width="80px"
                label-position="left"
              >
                <el-form-item>
                  <template slot="label"> 证明人 </template>
                  <el-input v-model="outWorkInfo.witnessName" readonly></el-input>
                </el-form-item>
                <el-form-item>
                  <template slot="label">
                    附件
                    <el-tooltip class="item" effect="light" placement="top">
                      <div slot="content" style="font-size: 18px; color: #000">
                        工作派遣、聊天记录、通知函。
                      </div>
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                  </template>
                  <div
                    v-for="(n, index) in outWorkInfo.manhourFileList"
                    :key="index"
                    style="margin-top: 5px"
                    class="file-list"
                  >
                    <a
                      class="fj-text file-name"
                      target="_blank"
                      style="text-decoration: revert; color: #409eff; padding-right: 8px"
                      :href="`${filepath}${n.filePath}`"
                    >
                      {{ n.fileName }}
                    </a>
                  </div>
                </el-form-item>
                <el-form-item label="外勤说明" prop="content" style="width: 100%">
                  <el-input
                    type="textarea"
                    v-model="outWorkInfo.content"
                    readonly
                    :autosize="{ minRows: 4, maxRows: 9999 }"
                    placeholder="请输入外勤说明"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div style="font-weight: 700; margin-bottom: 10px; padding-left: 10px">
                审批通过意见
              </div>
              <el-form :model="unPassForm" ref="unPassForm">
                <el-form-item prop="checkOpinion">
                  <el-input
                    placeholder="请填写审批通过意见"
                    v-model.trim="unPassForm.checkOpinion"
                    type="textarea"
                  ></el-input>
                </el-form-item>
              </el-form>
              <el-alert
                title="审批意见超过两个字将发微信消息通知成员"
                :type="infoType"
                style="margin-bottom: 10px"
              >
              </el-alert>
            </div>
          </div>
        </template>
        <template v-else-if="dg_form.outgoingType != 'HAVE' && dg_form.isTraveling == 'Y'">
          <div class="hour-audit">
            <div class="out-report" style="min-height: 480px">
              <el-form
                style="width: 100%"
                :model="outWorkInfo"
                label-width="80px"
                label-position="left"
              >
                <el-form-item>
                  <template slot="label"> 证明人 </template>
                  <el-input v-model="outWorkInfo.witnessName" readonly></el-input>
                </el-form-item>
                <el-form-item>
                  <template slot="label">
                    附件
                    <el-tooltip class="item" effect="light" placement="top">
                      <div slot="content" style="font-size: 18px; color: #000">
                        工作派遣、聊天记录、通知函。
                      </div>
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                  </template>
                  <div
                    v-for="(n, index) in outWorkInfo.manhourFileList"
                    :key="index"
                    style="margin-top: 5px"
                    class="file-list"
                  >
                    <a
                      class="fj-text file-name"
                      target="_blank"
                      style="text-decoration: revert; color: #409eff; padding-right: 8px"
                      :href="`${filepath}${n.filePath}`"
                    >
                      {{ n.fileName }}
                    </a>
                  </div>
                </el-form-item>
                <el-form-item label="外勤说明" prop="content" style="width: 100%">
                  <el-input
                    type="textarea"
                    v-model="outWorkInfo.content"
                    readonly
                    :autosize="{ minRows: 4, maxRows: 9999 }"
                    placeholder="请输入外勤说明"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="text-center-btm">
              <div style="font-weight: 700; margin-bottom: 10px; padding-left: 10px">
                审批通过意见
              </div>
              <el-form :model="unPassForm" ref="unPassForm">
                <el-form-item prop="checkOpinion">
                  <el-input
                    placeholder="请填写审批通过意见"
                    v-model.trim="unPassForm.checkOpinion"
                    type="textarea"
                  ></el-input>
                </el-form-item>
              </el-form>
              <el-alert
                title="审批意见超过两个字将发微信消息通知成员"
                :type="infoType"
                style="margin-bottom: 10px"
              >
              </el-alert>
            </div>
          </div>
        </template>
        <template v-else>
          <el-form ref="unPassForm" :model="unPassForm">
            <el-form-item prop="checkOpinion" style="width: 100%">
              <el-input
                v-model.trim="unPassForm.checkOpinion"
                type="textarea"
                placeholder="请填写审批通过意见"
              ></el-input>
            </el-form-item>
          </el-form>

          <el-alert
            title="审批意见超过两个字将发微信通知告知成员"
            :type="infoType"
            style="margin-bottom: 10px"
          >
          </el-alert>
        </template>
      </template>
    </EditDialog>

    <!-- 审核不通过意见框 -->
    <EditDialog
      width="60%"
      :isShow.sync="unPassDialogVisible"
      @submit="submitUnPass"
      :loading.sync="editDialogLoading"
    >
      <template v-slot:title>
        <span style="color: #409eff">{{
          dg_form.outgoingType == 'HAVE'
            ? dg_form.isTraveling == 'Y'
              ? '重要活动报告与外勤报告和审批不通过意见'
              : '重要活动报告与审批不通过意见'
            : dg_form.isTraveling == 'Y'
            ? '外勤报告与审批不通过意见'
            : '审批不通过意见'
        }}</span>
      </template>
      <template v-slot:content>
        <template v-if="dg_form.outgoingType == 'HAVE'">
          <div class="hour-audit">
            <div class="out-report" style="min-height: 480px">
              <el-form
                style="width: 100%"
                :model="manhourOutgoing"
                ref="outReportForm"
                label-width="107px"
                label-position="left"
              >
                <el-form-item label="报告类型" prop="type">
                  <el-radio-group v-model="manhourOutgoing.type" disabled>
                    <el-radio label="WRITTEN">书面报告</el-radio>
                    <el-radio label="ORAL">口头报告</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="活动开始时间"
                  prop="startDate"
                  style="width: 50%; display: inline-block"
                >
                  <el-date-picker
                    v-model="manhourOutgoing.startDate"
                    align="right"
                    type="date"
                    placeholder="选择日期"
                    disabled
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item
                  label="活动结束时间"
                  prop="endDate"
                  style="width: 50%; display: inline-block"
                >
                  <el-date-picker
                    v-model="manhourOutgoing.endDate"
                    align="right"
                    type="date"
                    placeholder="选择日期"
                    disabled
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="活动地点" prop="place">
                  <el-input v-model.trim="manhourOutgoing.place" disabled></el-input>
                </el-form-item>
                <el-form-item :error="manhourOutgoing.fileErrMsg">
                  <template slot="label">
                    <template> 附件</template>
                  </template>
                  <div
                    v-for="(n, index) in manhourOutgoing.manhourFileList"
                    :key="index"
                    style="margin-top: 5px"
                    class="file-list"
                  >
                    <a
                      class="fj-text file-name"
                      target="_blank"
                      style="text-decoration: revert; color: #409eff; padding-right: 8px"
                      :href="`${filepath}${n.filePath}`"
                    >
                      {{ n.fileName }}
                    </a>
                  </div>
                </el-form-item>
                <el-form-item label="活动说明" prop="content ">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 8, maxRows: 9999 }"
                    v-model.trim="manhourOutgoing.content"
                    readonly
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="text-center-btm">
              <el-form
                v-if="dg_form.isTraveling == 'Y'"
                style="width: 100%"
                :model="outWorkInfo"
                label-width="80px"
                label-position="left"
              >
                <el-form-item>
                  <template slot="label"> 证明人 </template>
                  <el-input v-model="outWorkInfo.witnessName" readonly></el-input>
                </el-form-item>
                <el-form-item>
                  <template slot="label">
                    附件
                    <el-tooltip class="item" effect="light" placement="top">
                      <div slot="content" style="font-size: 18px; color: #000">
                        工作派遣、聊天记录、通知函。
                      </div>
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                  </template>
                  <div
                    v-for="(n, index) in outWorkInfo.manhourFileList"
                    :key="index"
                    style="margin-top: 5px"
                    class="file-list"
                  >
                    <a
                      class="fj-text file-name"
                      target="_blank"
                      style="text-decoration: revert; color: #409eff; padding-right: 8px"
                      :href="`${filepath}${n.filePath}`"
                    >
                      {{ n.fileName }}
                    </a>
                  </div>
                </el-form-item>
                <el-form-item label="外勤说明" prop="content" style="width: 100%">
                  <el-input
                    type="textarea"
                    v-model="outWorkInfo.content"
                    readonly
                    :autosize="{ minRows: 4, maxRows: 9999 }"
                    placeholder="请输入外勤说明"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div style="font-weight: 700; margin-bottom: 10px; padding-left: 10px">
                <i style="color: red">*</i> 审批不通过意见
              </div>
              <el-form :model="unPassForm" ref="unPassForm" :rules="unPassRules">
                <el-form-item prop="checkOpinion">
                  <el-input
                    placeholder="请填写审批不通过意见"
                    v-model="unPassForm.checkOpinion"
                    type="textarea"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </template>
        <template v-else-if="dg_form.outgoingType != 'HAVE' && dg_form.isTraveling == 'Y'">
          <div class="hour-audit">
            <div class="out-report" style="min-height: 480px">
              <el-form
                style="width: 100%"
                :model="outWorkInfo"
                label-width="80px"
                label-position="left"
              >
                <el-form-item>
                  <template slot="label"> 证明人 </template>
                  <el-input v-model="outWorkInfo.witnessName" readonly></el-input>
                </el-form-item>
                <el-form-item>
                  <template slot="label">
                    附件
                    <el-tooltip class="item" effect="light" placement="top">
                      <div slot="content" style="font-size: 18px; color: #000">
                        工作派遣、聊天记录、通知函。
                      </div>
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                  </template>
                  <div
                    v-for="(n, index) in outWorkInfo.manhourFileList"
                    :key="index"
                    style="margin-top: 5px"
                    class="file-list"
                  >
                    <a
                      class="fj-text file-name"
                      target="_blank"
                      style="text-decoration: revert; color: #409eff; padding-right: 8px"
                      :href="`${filepath}${n.filePath}`"
                    >
                      {{ n.fileName }}
                    </a>
                  </div>
                </el-form-item>
                <el-form-item label="外勤说明" prop="content" style="width: 100%">
                  <el-input
                    type="textarea"
                    v-model="outWorkInfo.content"
                    readonly
                    :autosize="{ minRows: 4, maxRows: 9999 }"
                    placeholder="请输入外勤说明"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="text-center-btm">
              <div style="font-weight: 700; margin-bottom: 10px; padding-left: 10px">
                <i style="color: red">*</i> 审批不通过意见
              </div>
              <el-form :model="unPassForm" ref="unPassForm" :rules="unPassRules">
                <el-form-item prop="checkOpinion">
                  <el-input
                    placeholder="请填写审批不通过意见"
                    v-model="unPassForm.checkOpinion"
                    type="textarea"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </template>
        <template v-else>
          <el-form ref="unPassForm" :model="unPassForm" :rules="unPassRules">
            <el-form-item prop="checkOpinion" style="width: 100%">
              <el-input
                v-model="unPassForm.checkOpinion"
                type="textarea"
                placeholder="请填写审批不通过意见"
              ></el-input>
            </el-form-item>
          </el-form>
        </template>
      </template>
    </EditDialog>

    <SelectDialog
      ref="SelectDialog"
      :options="{ title: '转交工时审核', type: 'radio' }"
      @selectDataChange="Deliver_submit"
    >
    </SelectDialog>

    <OutReportDetail
      v-if="isOutReportDialogShow"
      :isOutReportDialogShow.sync="isOutReportDialogShow"
      :outReportData="manhourOutgoing"
    >
    </OutReportDetail>

    <OutWorkDetail
      v-if="isOutWorkDialogShow"
      :isOutWorkDialogShow.sync="isOutWorkDialogShow"
      :outWorkInfo="outWorkInfo"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    SelectDialog: () => import('@/components/selectDialog.vue'),
    OutReportDetail: () => import('@/components/hour/out-report-detail.vue'),
    OutWorkDetail: () => import('@/components/hour/out-work-detail.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        deptId: '',
      },
      pageSize: 0,
      form: {},
      dg_form: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
      editDialogLoading: false,
      multipleSelection: [],
      activeName: '',
      isDetail: false,
      zgs: 0,
      projectList: [],
      //工时移交用到字段
      showDeliver: false,
      userList: [], //用户列表
      dgDeliverForm: [], //工时移交弹框数据
      all_deliver_arr: [], //批量移交数据
      orgObject: [],
      workContentStyleOpen: {
        'white-space': 'pre-line',
      },
      workContentStyleClose: {},
      isWorkContentOpen: false,
      workContentDialogVisible: false,
      passDialogVisible: false,
      unPassDialogVisible: false,
      workContentDialog: '',

      auditHour: '', //* 审核工时
      isAuditHourTipsShow: false,
      auditHourTips: '',

      manhourOutgoing: null, //外出活动报告数据源
      isOutReportDialogShow: false,
      filepath: process.env.VUE_APP_FILEPATH,
      fields: {
        name: 'fileName',
        path: 'filePath',
        type: 'type',
      },
      unPassRules: {
        checkOpinion: [{ required: true, message: '请输入审核意见', trigger: 'blur' }],
      },
      unPassForm: {
        checkOpinion: null,
      },

      label_style: {
        width: '16.6%',
        'word-break': 'keep-all',
      },
      content_style: {
        'word-break': 'break-all', //过长时自动换行
      },
      outWorkInfo: {}, //外勤数据源
      isOutWorkDialogShow: false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
    infoType() {
      if (this.unPassForm.checkOpinion && this.unPassForm.checkOpinion.length > 2) {
        return 'warning'
      } else {
        return 'info'
      }
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getProject()
    // this.getUserList()
    this.getOrgObj()
    // 初始化防抖函数
    this.debouncedFetchProjects = this.debounce(this.fetchProjects, 1000)
  },
  watch: {
    activeName: {
      // immediate:true,//初始化立即执行
      handler: function (newVal, oldVal) {
        this.parameter.pageNow = 1
        this.getData()
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
    'parameter.userName': {
      // immediate:true,//初始化立即执行
      // deep: true,
      handler: function (newVal) {
        this.projectList = []
        if (newVal) {
          this.debouncedFetchProjects(newVal)
        } else {
          this.getProject()
        }
      },
    },
  },
  methods: {
    fetchProjects() {
      this.$api.project
        .listProjectByUser({
          userName: this.parameter.userName,
          pageSize: 10000,
        })
        .then(res => {
          this.projectList = res.data.records
        })
        .catch(err => {
          console.log(err)
        })
    },
    debounce(func, wait) {
      let timeout
      return function (...args) {
        const context = this
        // 清除之前的定时器
        clearTimeout(timeout)
        // 设置新的定时器
        timeout = setTimeout(() => {
          func.apply(context, args)
        }, wait)
      }
    },
    blurFn() {
      this.projectList = []
      if (this.parameter.userName) {
        this.$api.project
          .listProjectByUser({
            userName: this.parameter.userName,
            pageSize: 10000,
          })
          .then(res => {
            this.projectList = res.data.records
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.getProject()
      }
    },
    getData() {
      this.loading = true
      let obj = {
        status: this.activeName,
        ...this.parameter,
      }
      this.$api.hour
        .getAuditManhourList(obj)
        .then(res => {
          this.loading = false
          if (res.data == null) {
            this.tableData = []
          } else {
            let arr = []
            for (let i = 0; i < res.data.records.length; i++) {
              const e = res.data.records[i]
              e.manhourDate = new Date(e.manhourDate).format('yyyy-MM-dd')
              arr.push(e)
            }
            this.tableData = arr
            this.parameter.total = res.data.total
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    getDaySum() {
      let str = this.dg_form.manhourDate + '/' + this.dg_form.userId
      this.$api.hour
        .getDaySum(str)
        .then(res => {
          this.loading = false
          this.zgs = res.data
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    audit(row, status) {
      this.auditHour = row.hour //审核工时默认为当前填报工时
      this.isAuditHourTipsShow = false
      this.auditHourTips = ''
      this.showEditDialog = true
      this.isWorkContentOpen = false
      this.manhourOutgoing = {} //改为空对象，避免弹窗报错
      if (row.outgoingType == 'HAVE') {
        // 需要调接口获取外出活动内容
        this.$api.hour
          .selectManhourOutgoingDetailByManhourId(row.id)
          .then(res => {
            if (res.data) {
              this.manhourOutgoing = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
      if (row.isTraveling == 'Y') {
        // 需要调接口获取外出活动内容
        this.$api.hour
          .selectDetailByManhourId(row.id)
          .then(res => {
            if (res.data) {
              this.outWorkInfo = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      }

      this.dg_form = row
      // this.dg_form.isCheckPass = 2
      // this.dg_form.checkOpinion = ''

      this.getDaySum()
      if (status) {
        this.isDetail = true
      } else {
        this.isDetail = false
      }
    },

    is_number() {
      this.auditHour = this.auditHour.replace(/[^\d.]/g, '')
    },

    isNumber(val) {
      var regPos = /^\d+(\.\d+)?$/ //非负浮点数
      var regNeg =
        /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/ //负浮点数
      if (regPos.test(val) || regNeg.test(val)) {
        return true
      } else {
        return false
      }
    },

    checkAuditHour(hour) {
      // 判断审核工时是否是合规数字
      let flag = this.isNumber(this.auditHour)
      if (!flag) {
        this.auditHourTips = '请输入合规的时间'
        this.isAuditHourTipsShow = true
        return
      }
      // 输入的审核工时不能超过填报工时
      if (Number(this.auditHour) > Number(hour)) {
        this.auditHourTips = '审核工时不得超过填报工时'
        this.isAuditHourTipsShow = true
        return
      }
      this.isAuditHourTipsShow = false
      this.auditHourTips = ''
    },

    getObjectKey(value) {
      const findResult = Object.keys(this.orgObject).find(key => this.orgObject[key] == value)
      if (findResult) {
        return findResult
      } else {
        return ''
      }
    },
    // 统一提交接口
    unifySibmit() {
      if (this.dg_form.isCheckPass == 3 && !this.unPassForm.checkOpinion) {
        this.$message('不通过请填写说明！')
        return
      }
      this.dg_form.checkOpinion = this.unPassForm.checkOpinion
      if (this.dg_form.isCheckPass == 2 && !this.unPassForm.checkOpinion) {
        this.dg_form.checkOpinion = '通过'
      }

      this.dg_form.isCheckPass == 2
        ? (this.dg_form.checkStatus = 2)
        : (this.dg_form.checkStatus = 3)
      // todo 添加审核工时
      if (this.dg_form.isCheckPass == 2) {
        this.dg_form.auditHour = this.auditHour
      }
      this.editDialogLoading = true
      this.$api.hour
        .checkManhours([{ ...this.dg_form }])
        .then(async res => {
          await this.getData()
          this.editDialogLoading = false
          this.passDialogVisible = false
          this.unPassDialogVisible = false
          const arr = this.tableData.filter(item => item.id !== this.dg_form.id)
          if (arr.length > 0) {
            this.audit(arr[0], 0)
          } else {
            this.showEditDialog = false
          }
        })
        .catch(err => {
          console.log(err)
          this.$message.error('提交失败，请稍后再试！')
          this.editDialogLoading = false
        })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    all_audit() {
      let arr = []
      if (this.multipleSelection.length > 0) {
        for (let i = 0; i < this.multipleSelection.length; i++) {
          const e = this.multipleSelection[i]
          e.isCheckPass = 2
          e.checkStatus = 2
          arr.push(e)
        }
      }
      this.$api.hour
        .checkManhours(arr)
        .then(res => {
          this.getData()
          this.editDialogLoading = false
          this.showEditDialog = false
        })
        .catch(err => {
          console.log(err)
          this.editDialogLoading = false
        })
    },
    all_deliver() {
      let arr = []
      if (this.multipleSelection.length > 0) {
        for (let i = 0; i < this.multipleSelection.length; i++) {
          const e = this.multipleSelection[i].id
          arr.push(e)
        }
        this.all_deliver_arr = arr
        this.$refs.SelectDialog.dlgVisible = true
      } else {
        this.$message.warning('请先勾选多条需要转交工时数据')
      }
    },
    handleReset() {
      // this.createdDate = [];
      this.parameter.pageNow = 1
      this.parameter.projectId = null
      this.parameter.userName = null

      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    dataUpdate(row) {
      this.$forceUpdate()
    },
    getProject() {
      this.$api.project
        .getProjectList({ pageSize: 10000 })
        .then(res => {
          this.projectList = res.data ? res.data : []
        })
        .catch(err => {
          console.log(err)
        })
    },
    deliver(row) {
      // this.showDeliver = true
      this.dgDeliverForm = row
      this.all_deliver_arr = []
      this.$refs.multipleTable.clearSelection()
      this.$refs.SelectDialog.dlgVisible = true
    },
    Deliver_submit(row) {
      // var arr = this.$refs.principalUserDataTree.getCheckedNodes(true)
      let a = {}
      // arr.forEach(e => {
      a = {
        userId: row.id,
        ids: this.all_deliver_arr.length > 0 ? this.all_deliver_arr : [this.dgDeliverForm.id],
        userName: row.userName,
      }
      // })

      this.$confirm('确定将此条工时给 ' + a.userName + '  审核?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const res = await this.$api.hour.transferManhours(a)
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '移交成功!',
            })
            await this.getData()
            const arr = this.tableData.filter(item => item.id !== this.dg_form.id)
            this.editDialogLoading = false
            this.loading = false
            if (arr.length > 0) {
              this.audit(arr[0], 0)
            } else {
              this.showEditDialog = false
            }
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
      this.all_deliver_arr = []
      this.$refs.multipleTable.clearSelection()
    },
    // loadNode(node, resolve) {
    //   console.log(node)
    //   if (node.level === 0) {
    //     resolve(this.userList)
    //   } else {
    //     if (node.data.type == 'dept') {
    //       this.$api.user
    //         .staffList({
    //           deptId: node.data.deptId,
    //         })
    //         .then(res => {
    //           var arr = []
    //           res.data.some(r => {
    //             arr.push(
    //               r.combination({
    //                 leaf: true,
    //                 deptId: node.data.deptId,
    //               })
    //             )
    //           })
    //           resolve(arr)
    //         })
    //         .catch(err => {})
    //     } else {
    //       resolve([])
    //     }
    //   }
    // },
    // getUserList() {
    //   this.$api.sysDept.listDept({}).then(res => {
    //     this.deptData = res.data
    //     res.data.some(r => {
    //       this.userList.push({
    //         deptId: r.id,
    //         label: r.userName,
    //         icon: 'iconcompany',
    //         type: 'dept',
    //         disabled: true,
    //       })
    //     })
    //   })
    // },
    // treeCheck(data, checked) {
    //   if (checked) {
    //     //单选实现
    //     this.$refs.principalUserDataTree.setCheckedKeys([data.id])
    //   }
    // },
    getOrgObj() {
      this.$api.hour
        .organizationManhourType()
        .then(res => {
          this.orgObject = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    getObjectKey(value) {
      const findResult = Object.keys(this.orgObject).find(key => this.orgObject[key] == value)
      if (findResult) {
        return findResult
      } else {
        return ''
      }
    },

    // 打开预览工作内容
    handleCellClick(row, column) {
      if (column.label == '工作内容') {
        this.workContentDialog = row.workContent
        this.workContentDialogVisible = true
      }
    },

    // 点击工作内容展开
    openWorkContent() {
      this.isWorkContentOpen = !this.isWorkContentOpen
    },

    // 弹出审核通过意见框
    showPassOpinion() {
      if (!this.auditHour || this.isAuditHourTipsShow) return this.$message('请填写正确的审核工时')
      this.unPassForm.checkOpinion = null
      this.passDialogVisible = true
    },

    // 弹出审核不通过意见框
    showUnPassOpinion() {
      this.unPassForm.checkOpinion = null
      this.unPassDialogVisible = true
    },

    // 点击审核通过
    submitPass() {
      // 审核通过赋状态值
      this.dg_form.isCheckPass = 2
      this.unifySibmit()
    },

    // 点击审核不通过
    submitUnPass() {
      this.$refs.unPassForm.validate(valid => {
        if (valid) {
          // 审核通过赋状态值
          this.dg_form.isCheckPass = 3
          this.unifySibmit()
        }
      })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.annex {
  display: flex;
  justify-content: space-between;

  /deep/.el-card {
    width: 50%;
    margin: 0 10px;

    .el-card__header {
      padding: 10px 15px;
      font-size: 16px;
      font-weight: bold;
    }

    .el-table {
      height: calc(100vh - 347px);
    }
  }
}

.dg_title {
  color: #333333;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
}

.content {
  padding: 0 20px !important;
  .dg_title {
    margin-bottom: 5px !important;
  }
  .el-form {
    display: block !important;
    .descriptions {
      width: 100%;
    }
    .el-form-item {
      margin-bottom: 5px !important;
    }
  }
}

.el-link {
  font-size: 12px;
  margin-left: 20px;
}

.workContentStyleOpen {
  white-space: pre-line;
  min-width: 350px;
  min-height: 300px;
}

.workContentStyleClose {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: pre;
}

.dialogContentHeight {
  height: 250px;
  overflow: auto;
  white-space: pre-wrap;
  padding: 0px 20px;
}

.color_r {
  color: #f00;
  margin-top: 5px;
}

.hour-audit {
  display: flex;
  .out-report {
    // width: 50%;
    padding-right: 20px;
    border-right: 1px solid #efefef;
    flex: 1;
  }
  .text-center-btm {
    padding-left: 20px;
    width: 40%;
  }
}

.upload-btn-box {
  display: flex;
  align-items: center;
}
.icon-out {
  cursor: pointer;
  font-size: 28px;
  padding-left: 10px;
  vertical-align: middle;
}
.file-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .file-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

/deep/.projectType {
  background-color: #a7f0f5;
}
/deep/.businessType {
  background-color: #f5a9a7;
}
/deep/.deptType {
  background-color: #a7f5a7;
}
</style>
